import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { updateTradingPreferencesWeb } from "../../../../redux/actions/authActions";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({ accountPlatform: "MT5" });
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 4) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(1);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props]);

  const handleChange = (value, target) => {
    setUserData(prevState => {
      return {
        ...prevState,
        [target]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    setDisableButton(true);

    const tradingPreferences = {
      step: 4,
      subStep: 2,
      userId: props.auth.user.id,
      // accountPlatform: userData.accountPlatform,
      accountPlatform: "MT5",
    };

    props.updateTradingPreferencesWeb(tradingPreferences);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Account details</h1>
          <h2 className={classes.h2}>Platform</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Platform</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label className={classes.label}>
                  Trading platform:
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              {/*<Form.Group*/}
              {/*  as={Col}*/}
              {/*  className={classes.bigChoiceBoxCheckWrapper}*/}
              {/*  onClick={() => handleChange("MT4", "accountPlatform")}*/}
              {/*>*/}
              {/*  <Form.Text*/}
              {/*    className={[*/}
              {/*      classes.bigChoiceBox,*/}
              {/*      userData.accountPlatform &&*/}
              {/*      userData.accountPlatform === "MT4"*/}
              {/*        ? classes.choiceBoxActive*/}
              {/*        : "",*/}
              {/*    ].join(" ")}*/}
              {/*  >*/}
              {/*    <span*/}
              {/*      className={[*/}
              {/*        classes.bigChoiceBoxCheck,*/}
              {/*        classes.bigChoiceBoxCheckText,*/}
              {/*      ].join(" ")}*/}
              {/*    >*/}
              {/*      MT4*/}
              {/*    </span>*/}
              {/*    <span className={classes.bigChoiceBoxBottom}>*/}
              {/*      MetaTrader 4*/}
              {/*    </span>*/}
              {/*  </Form.Text>*/}
              {/*</Form.Group>*/}
              <Form.Group
                as={Col}
                className={classes.bigChoiceBoxCheckWrapper}
                onClick={() => handleChange("MT5", "accountPlatform")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.accountPlatform &&
                    userData.accountPlatform === "MT5"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span
                    className={[
                      classes.bigChoiceBoxCheck,
                      classes.bigChoiceBoxCheckText,
                    ].join(" ")}
                  >
                    MT5
                  </span>
                  <span className={classes.bigChoiceBoxBottom}>
                    MetaTrader 5
                  </span>
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>
            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  updateTradingPreferencesWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateTradingPreferencesWeb })(Step);
