import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/authActions";
import classes from "./RegisterNew.module.css";
import logo from "../../../assets/images/iQuoto-Global-Logo-Blue.svg";
import { registrationStepsMatrix } from "../../../config/constants";
import { isMobileOnly } from "react-device-detect";
import CreateYourAccount from "./Step1/CreateYourAccount";
import AlreadyHaveAnAccount from "./Step1/AlreadyHaveAnAccount";
import VerifyEmail from "./Step1/VerifyEmail";
import VerifiedEmail from "./Step1/VerifiedEmail";
import ContactDetails from "./Step2/ContactDetails";
import DateOfBirth from "./Step2/DateOfBirth";
import ResidentialAddress from "./Step2/ResidentialAddress";
import ResidentialAddressPrevious from "./Step2/ResidentialAddressPrevious";
import Nationality from "./Step2/Nationality";
import EmploymentInformation from "./Step2/EmploymentInformation";
import FinancialInformation from "./Step2/FinancialInformation";
import TradingExperience from "./Step3/TradingExperience";
// import TradingKnowledge from "./Step3/TradingKnowledge";
import BaseCurrency from "./Step4/BaseCurrency";
import Platform from "./Step4/Platform";
import AccountType from "./Step4/AccountType";
import RiskDisclosure from "./Step5/RiskDisclosure";
import Declaration from "./Step5/Declaration";
import Completed from "./StepsCompleted/Completed";
import { FiLogOut } from "react-icons/fi";

const Register = props => {
  const [step, setStep] = useState(null);
  const [subStep, setSubstep] = useState(null);

  useEffect(() => {
    if (!localStorage.step || !localStorage.substep) {
      const currentStep = props.auth.user.lastOnboardingStep
        ? parseInt(props.auth.user.lastOnboardingStep)
        : 1;
      localStorage.setItem("step", currentStep.toString());
      setStep(currentStep);

      const currentSubstep = props.auth.user.lastOnboardingSubstep
        ? parseInt(props.auth.user.lastOnboardingSubstep)
        : 1;
      localStorage.setItem("substep", currentSubstep.toString());
      setSubstep(currentSubstep);

      const firstStep =
        (currentStep === 1 && currentSubstep === 1) ||
        (currentStep === 1 &&
          currentSubstep === 2 &&
          props.auth.user.showVerifyEmail);
      if (!firstStep) handleChangeStep(1);
    } else {
      setStep(parseInt(localStorage.step));
      setSubstep(parseInt(localStorage.substep));
    }
  }, [props.auth.user]);

  const handleChangeStep = inc => {
    const currStep = parseInt(localStorage.step);
    const currSubStep = parseInt(localStorage.substep);

    let nextSubstep = currSubStep + inc;
    if (registrationStepsMatrix[currStep].includes(nextSubstep)) {
      // setSubstep(nextSubstep);
      localStorage.setItem("substep", nextSubstep.toString());
    } else if (nextSubstep === 0) {
      let nextStep = currStep + inc;
      let nextSubstep =
        registrationStepsMatrix[nextStep][
          registrationStepsMatrix[nextStep].length - 1
        ];
      // setStep(nextStep);
      // setSubstep(nextSubstep);
      localStorage.setItem("step", nextStep.toString());
      localStorage.setItem("substep", nextSubstep.toString());
    } else {
      // setStep(step + inc);
      // setSubstep(inc);
      localStorage.setItem("step", (currStep + inc).toString());
      localStorage.setItem("substep", inc.toString());
    }

    window.location.reload();
  };

  let StepModule;
  switch (step) {
    case 1:
      switch (subStep) {
        case 0:
          StepModule = <AlreadyHaveAnAccount />;
          break;
        case 1:
          StepModule = <CreateYourAccount nextStep={handleChangeStep} />;
          break;
        case 2:
          StepModule = <VerifyEmail />;
          break;
        case 3:
          StepModule = <VerifiedEmail nextStep={handleChangeStep} />;
          break;
        default:
          StepModule = null;
      }
      break;
    case 2:
      switch (subStep) {
        case 1:
          StepModule = <ContactDetails nextStep={handleChangeStep} />;
          break;
        case 2:
          StepModule = <DateOfBirth nextStep={handleChangeStep} />;
          break;
        case 3:
          StepModule = <ResidentialAddress nextStep={handleChangeStep} />;
          break;
        case 4:
          StepModule = (
            <ResidentialAddressPrevious nextStep={handleChangeStep} />
          );
          break;
        case 5:
          StepModule = <Nationality nextStep={handleChangeStep} />;
          break;
        case 6:
          StepModule = <EmploymentInformation nextStep={handleChangeStep} />;
          break;
        case 7:
          StepModule = <FinancialInformation nextStep={handleChangeStep} />;
          break;
        default:
          StepModule = null;
      }
      break;
    case 3:
      switch (subStep) {
        case 1:
          StepModule = <TradingExperience nextStep={handleChangeStep} />;
          break;
        // case 2:
        //   StepModule = <TradingKnowledge nextStep={handleChangeStep} />;
        //   break;
        default:
          StepModule = null;
      }
      break;
    case 4:
      switch (subStep) {
        case 1:
          StepModule = <BaseCurrency nextStep={handleChangeStep} />;
          break;
        case 2:
          StepModule = <Platform nextStep={handleChangeStep} />;
          break;
        case 3:
          StepModule = <AccountType nextStep={handleChangeStep} />;
          break;
        default:
          StepModule = null;
      }
      break;
    case 5:
      switch (subStep) {
        case 1:
          StepModule = <RiskDisclosure nextStep={handleChangeStep} />;
          break;
        case 2:
          StepModule = <Declaration nextStep={handleChangeStep} />;
          break;
        default:
          StepModule = null;
      }
      break;
    case 6:
      StepModule = <Completed nextStep={handleChangeStep} />;
      break;
    default:
      StepModule = null;
  }

  return (
    <div className="registerPage">
      <div className={classes.registerLogoWrapper}>
        {isMobileOnly ? (
          <object
            data={logo}
            width="172"
            height="65"
            className={classes.registerLogo}
            onClick={() => (window.location.href = "/")}
            aria-label="iQuoto.com"
          />
        ) : null}

        {props.auth.isAuthenticated && step >= 1 ? (
          <span className={classes.registerLogout} onClick={props.logoutUser}>
            <FiLogOut />
          </span>
        ) : null}
      </div>

      {StepModule}
    </div>
  );
};

Register.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Register));
